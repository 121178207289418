import React, { useState, useEffect, useContext } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, Brush } from 'recharts';

import {
    Box, 
    Typography,
    Button,
    Skeleton
} from '@mui/material'

import { useTheme } from '@mui/material/styles';

import CalendarHeatmap from "react-calendar-heatmap";
import ReactTooltip from "react-tooltip";

import "react-calendar-heatmap/dist/styles.css";
import { api } from '../../utils/defaults';
import axios from 'axios';
import { UserContext } from '../../context/handleContext';
import logout from '../../utils/logout';

import useInterval from 'use-interval'

const day = {
    1 : 'Jan',
    2 : 'Fev',
    3 : 'Mar',
    4 : 'Abr',
    5 : 'Mai',
    6 : 'Jun',
    7 : 'Jul',
    8 : 'Ago',
    9 : 'Set',
    10 : 'Out',
    11 : 'Nov',
    12 : 'Dez'
}

export default function Index () {
    const [user, setUser] = useContext(UserContext)
    const [trinta, setTrinta] = useState([])
    const [mes, setMes] = useState([])
    const [semana, setSemana] = useState([])
    const [dia, setDia] = useState([])

    const theme = useTheme()
    const [show, setShow] = useState({pedido:true,valor:true})
    const [canceled, setCanceled] = useState(false)
    const [loadingData, setLoadingData] = useState({})

    const update = () => {
        setLoadingData({
            thirty : true,
            month : true,
            week : true,
            day : true, 
            daysPerStore : true
        })
        axios
        .get(`${api}/api/manage/dashboard/order/thirty?canceled=${canceled}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setTrinta(response.data))
        .catch(err => {
            if(err.response.status===401) logout()
        })
        .finally(() => setLoadingData({...loadingData,thirty:false}))

        axios
        .get(`${api}/api/manage/dashboard/order/month?canceled=${canceled}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setMes(response.data))
        .catch(err => console.log(err))
        .finally(() => setLoadingData({...loadingData,month:false}))

        axios
        .get(`${api}/api/manage/dashboard/order/week?canceled=${canceled}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setSemana(response.data))
        .catch(err => console.log(err))
        .finally(() => setLoadingData({...loadingData,week:false}))

        axios
        .get(`${api}/api/manage/dashboard/order/day?canceled=${canceled}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setDia(response.data))
        .catch(err => console.log(err))
        .finally(() => setLoadingData({...loadingData,day:false}))
    }

    useEffect(() => update(),[])
    useEffect(() => update(),[canceled])
    useInterval(() => update(),300000)

    return (
        <div style={{
            display:'flex',
            justifyContent:'space-around',
            flexWrap:'wrap',
            overflowY:'auto',
            height:'calc(100% - 64px)',
            marginBottom:'20px'
        }}>
            <Box 
            sx={{
                width:'calc(100% - 60px)',
                margin:'20px 20px 0px 20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:'10px',
                borderRadius:'5px',
            }}>
                <Button 
                onClick={() => setShow({
                    pedido: !show.pedido,
                    valor: (!show.pedido===false && !show.valor) ? true : show.valor
                })}
                sx={{
                    margin:'0px 10px 0px 10px',
                    color:show.pedido ? '#000' : '#8884d8',
                    borderColor:'#8884d8',
                    backgroundColor:show.pedido && '#8884d8'
                }} variant={show.pedido ? 'contained' : 'outlined'}>pedido</Button>
                
                <Button 
                onClick={() => setShow({
                    pedido: (!show.valor===false && !show.pedido) ? true : show.pedido,
                    valor: !show.valor
                })}
                sx={{
                    margin:'0px 10px 0px 10px',
                    color:show.valor ? '#000' : '#82ca9d',
                    borderColor:'#82ca9d',
                    backgroundColor:show.valor && '#82ca9d'
                }} variant={show.valor ? 'contained' : 'outlined'}>valor</Button>

                <Button 
                onClick={() => setCanceled(!canceled)}
                color='error'
                sx={{
                    margin:'0px 10px 0px 10px'
                }} variant={canceled ? 'contained' : 'outlined'}>cancelados</Button>
            </Box>
            <Box 
            sx={{
                width:'calc(100% - 60px)',
                margin:'20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:'10px',
                borderRadius:'5px',
                height:'400px',
                paddingBottom:'50px'
            }}>
                <div style={{margin:'5px 0px 20px 10px'}}>
                    <Typography sx={{fontWeight:900}}>Vendas dos últimos 30 dias.</Typography>
                </div>
                {loadingData.thirty ?
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} /> :
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                    width={500}
                    height={300}
                    data={trinta.map(e => ({...e,data:new Date(e.purchase_date)?.toLocaleDateString('pt-BR'), pedidos: parseInt(e.pedidos)}))}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    onClick={data => console.log(data)}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="data" />
                    <YAxis />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    {show.pedido && <Line type="monotone" dataKey="pedidos" yAxisId="right" stroke="#8884d8" strokeWidth={3} activeDot={{ r: 8 }} />}
                    {show.valor && <Line type="monotone" dataKey="valor" stroke="#82ca9d" strokeWidth={3} />}
                    <Brush dataKey="data" height={30} stroke="#8884d8" />
                    </ComposedChart>
                </ResponsiveContainer>}
            </Box>
            <Box 
            sx={{
                width:'calc(50% - 50px)',
                margin:'10px 10px 0px 20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:'10px',
                borderRadius:'5px',
                height:'400px',
                paddingBottom:'50px'
            }}>
                <div style={{margin:'5px 0px 20px 10px'}}>
                    <Typography sx={{fontWeight:900}}>Vendas por mês.</Typography>
                </div>
                {loadingData.month ? 
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} /> :
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                    width={500}
                    height={300}
                    data={mes.map(e => ({...e,data: day[e.mes], pedidos: parseInt(e.pedidos)}))}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    onClick={data => console.log(data)}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="data" />
                    <YAxis />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    
                    {show.valor && <Line type="monotone" dataKey="valor" stroke="#82ca9d" strokeWidth={3} />}
                    {show.pedido && <Line type="monotone" dataKey="pedidos" yAxisId="right" stroke="#8884d8" strokeWidth={3} activeDot={{ r: 8 }} />}
                    </LineChart>
                </ResponsiveContainer>}
            </Box>
            <Box 
            sx={{
                width:'calc(50% - 60px)',
                margin:'10px 20px 0px 10px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:'10px',
                borderRadius:'5px',
                height:'400px',
                paddingBottom:'50px'
            }}>
                <div style={{margin:'5px 0px 20px 10px'}}>
                    <Typography sx={{fontWeight:900}}>Vendas por dia da semana.</Typography>
                </div>
                {loadingData.week ? 
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} /> :
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                    width={500}
                    height={300}
                    data={semana.map(e => ({...e,data:e.dia_semana, pedidos: parseInt(e.pedidos)}))}
                    // data={teste}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    onClick={data => console.log(data)}
                    >
                    <CartesianGrid strokeDasharray="4 4" />
                    <XAxis dataKey="data" />
                    <YAxis />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    {show.valor && <Line type="monotone" dataKey="valor" stroke="#82ca9d" strokeWidth={3} />}
                    {show.pedido && <Line type="monotone" dataKey="pedidos" yAxisId="right" stroke="#8884d8" strokeWidth={3} activeDot={{ r: 8 }} />}
                    {/* {show.pedido && <Line type="monotone" dataKey="venda" yAxisId="right" stroke="#8884d8" strokeWidth={3} activeDot={{ r: 8 }} />} */}
                    
                    </ComposedChart>
                </ResponsiveContainer>}
            </Box>
            <Box
            sx={{
                width:'calc(100% - 60px)',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#fff',
                padding:'10px',
                borderRadius:'5px',
                paddingBottom:'50px',
                marginTop:'30px'
            }}>
                <div style={{margin:'5px 0px 20px 10px'}}>
                    <Typography sx={{fontWeight:900}}>Vendas por dia</Typography>
                </div>
                {loadingData.day ? 
                <Skeleton sx={{borderRadius:'10px'}} variant="rectangular" width={'100%'} height={"99%"} />:
                <>
                <CalendarHeatmap
                    startDate={new Date(new Date().getFullYear(), 0, 1)?.toLocaleDateString('sv-SE')}
                    endDate={new Date(new Date().getFullYear(), 11, 31)?.toLocaleDateString('sv-SE')}
                    values={dia.map(e => ({
                        date:new Date(e.purchase_date)?.toLocaleDateString('sv-SE'),
                        count: parseInt(show.valor ? e.valor : e.pedidos),
                        valor: e.valor,
                        pedidos: e.pedidos
                    }))}
                    classForValue={(value) => {
                    if (!value) {
                        return "color-empty";
                    }
                    return `color-github-${value.count}`;
                    }}
                    tooltipDataAttrs={(value) => {
                    return {
                        "data-tip": value.date ? `${new Date(value.date)?.toLocaleDateString('pt-BR')}, ${value.pedidos ? value.pedidos : 0} venda${value.pedidos===1 ? '' : 's'}, ${value.valor?.toLocaleString('pt-BR', {style:'currency',currency:'BRL'})}` : 'Sem dados'
                    };
                    }}
                    showWeekdayLabels={true}
                    onClick={data => console.log(data)}
                />
                <ReactTooltip />
                </>
                }
            </Box>

            <Box
            sx={{
                width:'calc(100% - 60px)',
                padding:'10px',
                borderRadius:'5px',
                height:'10px',
            }}>
            </Box>
        </div>
    )
}