const CryptoJS = require('crypto-js')

const encrypt = (msg, pass) => {
    return CryptoJS.AES.encrypt(msg,pass,{iv:pass,mode:CryptoJS.mode.CTR}).toString();
}
  
const decrypt = (transitmessage, pass) => {
    try {
        const bytes  = CryptoJS.AES.decrypt(transitmessage, pass,{iv:pass,mode:CryptoJS.mode.CTR})
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch { return false }
}

export { encrypt , decrypt }