const defaultColors = {
    background: {
        light: '#f7f1e6',
        dark: '#000'
    },
    border: {
        light: '',
        dark: ''
    },
    modal: {
        light: '#fff',
        dark: '#131313'
    },
    color: '#464696'
}

const videoApi = {
    videoLibraryID : 30596,
    cdn : 'vz-3a5fca9a-ecb.b-cdn.net'
}

//const api = 'http://localhost:8888'
const homolog = false
const api = homolog ? 'http://localhost:8888' : 'https://connect-api.lookapp.store'

export { defaultColors, videoApi, api }