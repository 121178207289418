import { useState, useEffect, useContext } from 'react'

import {
    Box,
    AppBar,
    Container,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Avatar,
    SwipeableDrawer,
    List,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material'

import { Toaster } from 'react-hot-toast';

import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { useTheme } from '@mui/material/styles';

import { useSwipeable } from 'react-swipeable'

import {
    ColorModeContext,
    UserContext,
    CompanyContext
} from '../../context/handleContext'

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; //vendas
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'; //configurações
import LeaderboardIcon from '@mui/icons-material/Leaderboard'; //painel
import PeopleIcon from '@mui/icons-material/People'; // usuario
import PublicIcon from '@mui/icons-material/Public'; //localização
import CampaignIcon from '@mui/icons-material/Campaign'; //marketing
import LocalOfferIcon from '@mui/icons-material/LocalOffer'; //tipo
import InboxIcon from '@mui/icons-material/Inbox'; //log
import MenuBookIcon from '@mui/icons-material/MenuBook'; //catalogo
import SummarizeIcon from '@mui/icons-material/Summarize';
import logout from '../../utils/logout';
import HomeIcon from '@mui/icons-material/Home';

import axios from 'axios'
import { api } from '../../utils/defaults';
import usePersistedState from '../../utils/usePersistedState';
import { useNavigate } from 'react-router-dom';

export default function Index (props) {
    const [user, setUser] = useContext(UserContext)
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [company, setCompany] = usePersistedState('company',[])

    const handleOpenUserMenu = event => setAnchorElUser(event.currentTarget)
    const handleCloseUserMenu = () => setAnchorElUser(null)

    const colorMode = useContext(ColorModeContext);
    const theme = useTheme();

    const [menu, setMenu] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        if (company.length===0) {
          axios
          .get(`${api}/api/manage/store`,{
            headers: {
              Authorization: user.token
            }
          })
          .then(response => setCompany(response.data))
          .catch(err => console.log(err))
        }
      },[])

    const list = () => (
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setMenu(false)}
          onKeyDown={() => setMenu(false)}
        >
          <List>
                <ListItem onClick={() => navigate('/')} disablePadding sx={{color:document.location.pathname==='/'&&'#f34545'}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <HomeIcon sx={{color:document.location.pathname==='/'&&'#f34545'}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Início'} />
                    </ListItemButton>
                </ListItem>
                <ListItem onClick={() => navigate('/dashboard')} disablePadding sx={{color:document.location.pathname==='/dashboard'&&'#f34545'}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <LeaderboardIcon sx={{color:document.location.pathname==='/dashboard'&&'#f34545'}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Painel'} />
                    </ListItemButton>
                </ListItem>
                <ListItem onClick={() => navigate('/vendas')} disablePadding sx={{color:document.location.pathname==='/vendas'&&'#f34545'}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <MonetizationOnIcon sx={{color:document.location.pathname==='/vendas'&&'#f34545'}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Vendas'} />
                    </ListItemButton>
                </ListItem>
                <ListItem onClick={() => navigate('/relatorio')} disablePadding sx={{color:document.location.pathname==='/relatorio'&&'#f34545'}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <SummarizeIcon sx={{color:document.location.pathname==='/relatorio'&&'#f34545'}}/>
                    </ListItemIcon>
                    <ListItemText primary={'relatorio'} />
                    </ListItemButton>
                </ListItem>
                {/* <ListItem disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                        <MenuBookIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Catálogo'} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                        <CampaignIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Marketing'} />
                    </ListItemButton>
                </ListItem> */}
          </List>
          <Divider />
          <List>
                {user.crud_store && 
                <ListItem onClick={() => navigate('/user')} disablePadding sx={{color:document.location.pathname==='/user'&&'#f34545'}}>
                    <ListItemButton>
                        <ListItemIcon>
                            <PeopleIcon sx={{color:document.location.pathname==='/user'&&'#f34545'}}/>
                        </ListItemIcon>
                        <ListItemText primary={'Usuários'} />
                    </ListItemButton>
                </ListItem>}
                {/* <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <LocalOfferIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Tipo'} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <PublicIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Localização'} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Logs'} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <MiscellaneousServicesIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Configurações'} />
                    </ListItemButton>
                </ListItem> */}
          </List>
        </Box>
    );

    const handlers = useSwipeable({
        //onSwiped: (eventData) => console.log("User Swiped!", eventData),
        onSwipedRight: (eventData) => setMenu(true)
    });
    
    return (
        <Box 
        sx={{
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.default',
            color: 'text.primary',
            position:'fixed',
            top:0,
            left:0,
            width:'100vw',
            height:'100vh'
          }}
        {...handlers}>
            <Toaster />

            <SwipeableDrawer
                anchor={'left'}
                open={menu}
                onClose={() => setMenu(false)}
                onOpen={() => setMenu(false)}
            >
                {list()}
            </SwipeableDrawer>
            
            <AppBar position="static" sx={{backgroundColor:theme.palette.mode==='dark'?'#020202':'#f34545'}}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>

                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => setMenu(true)}
                        color="inherit"
                        >
                        <MenuIcon />
                    </IconButton>

                    

                    <Box 
                    onClick={() => window.location = '/'}
                    sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' },justifyContent:'center',cursor:'pointer' }}>
                        <img style={{height:theme.palette.mode==='dark'?'60px':'37px'}} src={require(theme.palette.mode==='dark'?'../../static/logo.png':'../../static/logo-preto.png')} alt='logo lookas'></img>
                    </Box>

                    <Box 
                    onClick={() => window.location = '/'}
                    sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' },cursor:'pointer' }}>
                        <img style={{height:theme.palette.mode==='dark'?'60px':'37px'}} src={require(theme.palette.mode==='dark'?'../../static/logo.png':'../../static/logo-preto.png')} alt='logo lookas'></img>
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <IconButton 
                            size='large'
                            sx={{ marginRight:'10px' }} onClick={colorMode.toggleColorMode} color="inherit">
                                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                        
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt={user.fullname} src="/"/>
                        </IconButton>
                        
                        <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">{user.fullname}</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">Minha Conta</Typography>
                            </MenuItem>
                            <MenuItem onClick={logout}>
                                <Typography textAlign="center" sx={{width:'100%'}}>Sair</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            {props.children}
        </Box>
    )
}