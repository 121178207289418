import { useState, useEffect, useContext, useReducer } from 'react'
import { useTheme } from '@mui/material/styles';

import Cliente from './cliente'
import { 
    ButtonGroup,
    Box,
    Button
} from '@mui/material';

export default function Index (props) {
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const [relatorio, setRelatorio] = useState('cliente')
    const theme = useTheme()


    return (
        <div
        style={{
            display:'flex',
            //justifyContent:'space-around',
            flexWrap:'wrap',
            overflowY:'auto',
            height:'calc(100% - 80px)',
            marginBottom:'20px',
            //overflowX:'auto',
            width:'100%'
        }}>
            <Box
            sx={{
                margin:'20px 20px 0px 20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:'10px',
                borderRadius:'5px',
                width:'100%',
                height:'fit-content'
            }}>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button onClick={() => setRelatorio('cliente')}>Cliente</Button>
                </ButtonGroup>
            </Box>

            {relatorio === 'cliente' && 
            <div
            style={{
                height:'inherit',
                width:'calc(100% - 60px)'
            }}>
                <Cliente />
            </div>
            }
        </div>
    )
}