import { createContext } from 'react'

const ColorModeContext = createContext({ toggleColorMode: () => {} })
const HandleScreensContext = createContext({})
const PreferencesContext = createContext({})
const LoadingContext = createContext()
const UserContext = createContext()
const CompanyContext = createContext()

export { 
    ColorModeContext,
    HandleScreensContext,
    PreferencesContext,
    LoadingContext,
    UserContext,
    CompanyContext
}