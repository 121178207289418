import { useState, useEffect, useContext, useReducer } from 'react'
import { CompanyContext, LoadingContext, UserContext } from '../../context/handleContext';
import axios from 'axios';
import { api } from '../../utils/defaults';
import Table from '../table'
import { useTheme } from '@mui/material/styles';
import 'dayjs/locale/pt-br';
import {
    Box,
    TextField,
    Tooltip as MuiTooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material'

import { float, integer } from '../../utils/input'

import toast from 'react-hot-toast';

export default function Index (props) {
    const [tableData, setTableData] = useState([]) 
    const [order, setOrder] = useState(null)
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [selectedCompany, setSelectedCompany] = useState('')
    const [day, setDay] = useState(30)
    const [day_tmp, setDay_tmp] = useState(30)
    const [frequency, setFrequency] = useState('most')
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)

    const theme = useTheme()

    useEffect(() => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/client?frequency=${frequency}&store_id=${selectedCompany.store_id}&day=${day}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setTableData(response.data))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    },[selectedCompany, day, frequency])

    return (
        <Box
        sx={{
            margin:'20px 20px 0px 20px',
            backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
            padding:'10px',
            borderRadius:'5px',
            width:'100%'
        }}>
            <div
            style={{
                display:'flex',
                margin:'10px'
            }}>
                <FormControl sx={{marginRight:'10px', minWidth:'220px'}}>
                    <InputLabel>Loja</InputLabel>
                    <Select
                        value={selectedCompany}
                        onChange={({target}) => setSelectedCompany(target.value)}
                    >
                        {user.all_store && <MenuItem value=''>Todas</MenuItem>}
                        {company.map(e => (<MenuItem value={e}>{e.internal_code}</MenuItem>))}
                    </Select>
                </FormControl>

                <FormControl sx={{marginRight:'10px', minWidth:'220px'}}>
                    <InputLabel>Frequencia</InputLabel>
                    <Select
                    value={frequency}
                    onChange={({target}) => setFrequency(target.value)}
                    >
                        <MenuItem value='most'>Clientes frequentes</MenuItem>
                        <MenuItem value='least'>Clientes parados</MenuItem>
                    </Select>
                </FormControl>
                
                <TextField 
                value={day_tmp}
                onChange={({target}) => setDay_tmp(integer(target.value))}
                onBlur={({target}) => setDay(integer(target.value))}
                label='Dias'
                />
            </div>

            <Table
            list={tableData.map(e => (
                frequency === 'least' ? {
                    Nome: e.client_name,
                    Telefone: e.client_mobile_phone,
                    CEP: e.client_postal_code,
                    Email: e.client_email,
                    'Última compra': e.ultima_compra,
                    Endereco: e.client_address
                } : {
                    Nome: e.client_name,
                    Telefone: e.client_mobile_phone,
                    CEP: e.client_postal_code,
                    Email: e.client_email,
                    'Quantidade compras': e.quantidade_compras,
                    Endereco: e.client_address
                }
            ))} 
            header={[]}
            notVisible={[]}
            type={{'Última compra':{type:'date'}}}
            lineClick={e => {
            }}
            columnClick={()=>{}}
            onChange={e => {}}
            changeAll={items => {}}
            page={true}
            sort={true}
            filter={true}
            check={false}
            options={true}
            elementName={`clientes_${frequency === 'most' ? 'mais' : 'menos'}_${Date.now()}`}
            rowsPerPage={5}/>
        </Box>
    )
}