import { useState, useEffect, useContext, useReducer } from 'react'
import { CompanyContext, LoadingContext, UserContext } from '../../context/handleContext';
import axios from 'axios';
import { api } from '../../utils/defaults';
import useInterval from 'use-interval';
import logout from '../../utils/logout';
import Table from '../table'
import { useTheme } from '@mui/material/styles';
import 'dayjs/locale/pt-br';
import {
    Typography,
    Box,
    Button,
    TextField,
    Tooltip as MuiTooltip,
    CircularProgress,
    ButtonBase,
    Modal,
    Popover,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import MapIcon from '@mui/icons-material/Map';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { float, integer } from '../../utils/input'
import { Document, Page, Text, View, StyleSheet, Image, TableHeader, TableCell } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

import toast from 'react-hot-toast';
import dayjs from 'dayjs';

const styles = StyleSheet.create({
    table: {
        display: 'table',
        width: 'calc(100% - 40px)',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginTop: 10
      },
      tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
      },
      tableCellHeader: {
        margin: 'auto',
        marginTop: 5,
        fontSize: 12,
        fontWeight: 'bold',
        padding: 5,
        backgroundColor: '#f2f2f2',
        flex: 1,
      },
      tableCell: {
        margin: 'auto',
        marginTop: 5,
        fontSize: 10,
        padding: 5,
        flex: 1,
      },
});

export default function Index (props) {
    const [tableData, setTableData] = useState([]) 
    const [order, setOrder] = useState(null)
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const [status, setStatus] = useState([])
    const [selectedStatus, setSelectedStatus] = useState('')
    const [relatorio, setRelatorio] = useState(false)
    const [pdf, setPDF] = useState(false)
    const [pdf2, setPDF2] = useState(false)

    const [data, setData] = useState({
        inicio: dayjs(new Date(Date.now() - 3 * 24 * 60 * 60 * 1000).toISOString()),
        fim: dayjs(new Date().toISOString())
    })
    const theme = useTheme()

    const update = (status='') => {
        if (!data.inicio && !data.fim) return

        setLoading(true)
        axios
        .get(`${api}/api/manage/order?status=${status}${data.inicio ? `&inicio=${data.inicio.toISOString('pt-BR').split('T')[0]}` : ''}${data.fim ? `&fim=${data.fim.toISOString('pt-BR').split('T')[0]}` : ''}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            if (response.data.length === 0) toast.error('Sem resultados')
            setTableData(response.data.map(e => ({...e, data : new Date(e.data).toLocaleDateString('pt-BR')})))
        })
        .catch(err => {
            // if(err.response.status===401) logout()
            toast.error(err.response.data)
        })
        .finally(() => setLoading(false))
    }

    useEffect(() => {    
        setLoading(true)
        axios
        .get(`${api}/api/manage/order/status`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setStatus(response.data))
        .catch(err => {
            // if(err.response.status===401) logout()
        })
        .finally(() => setLoading(false))
    },[])

    useInterval(() => update(selectedStatus),300000)

    const [quantityAnchor, setQuantityAnchor] = useState(null);
    const [quantity, setQuantity] = useState(0)
    const [quantityIndex, setQuantityIndex] = useState(undefined)

    const save = () => {
        let o = order
        // if (o.complete_purchase.CheckoutDetails[quantityIndex].Quantity < quantity) {
        //     return toast.error('A nova quantidade não pode ser superior à antiga')
        // }
        
        o.complete_purchase.CheckoutDetails[quantityIndex].Quantity = quantity
        setOrder(o)
        forceUpdate()
    }

    useEffect(() => {
        if(quantityAnchor===null) setQuantity(0)
        if(quantityAnchor===null) setQuantityIndex(undefined)
    },[quantityAnchor])

    const send = () => {
        setLoading(true)
        axios
        .post( `${api}/api/2020-06/order/final`,{
            unique_purchase_id:order.unique_purchase_id,
            CheckoutDetails: order.complete_purchase.CheckoutDetails
        },{
            headers:{
                'lookas-api-apptoken': user.access_token,
                'lookas-api-appkey':'7vUxkJrtS1iBwFS4rZveNWnRDACemCOH'
            }
        })
        .then(response => {
            update()
            setOrder(null)
            toast.success('Sucesso!')
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false)) 
    }

    useEffect(() => update(selectedStatus),[
        selectedStatus,
        data.inicio,
        data.fim
    ])

    return (
        <div
        style={{
            display:'flex',
            justifyContent:'space-around',
            flexWrap:'wrap',
            overflowY:'auto',
            height:'calc(100% - 80px)',
            marginBottom:'20px',
            overflowX:'auto',
            width:'100%'
        }}>
            <Modal
            open={pdf}
            onClose={() => setPDF(false)}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div 
                style={{ 
                    width: '90vw', 
                    height: '90vh',
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center'
                }}>
                    <div
                    style={{
                        width:'70vw',
                        justifyContent:'right',
                        display:'flex'
                    }}>
                    <Button
                    color='error'
                    variant='contained'
                    sx={{marginBottom:'10px'}}
                    onClick={() => setPDF(false)}
                    >fechar</Button>
                    </div>
                    <PDFViewer style={{ width: '70vw', height: 'calc(90vh - 66px)' }}>
                        <Document
                        style={{
                            flexDirection: 'row'
                        }}
                        >
                            <Page 
                            size="A4" 
                            style={{
                                width:'100%',
                                height:'100%'
                            }}>
                                <View 
                                style={{
                                    padding:'10px'
                                }}>
                                    <Image
                                    style={{
                                        width:'100px'
                                    }}
                                    src={require('../../static/logo.png')}
                                    />
                                    <Text
                                    style={{
                                        width:'100%',
                                        textAlign:'center',
                                        fontSize:'16px',
                                        marginTop:'20px'
                                    }}
                                    >Relatório de Vendas {data.loja && data.loja !== null ? data.loja : ''}</Text>
                                    {data.inicio ? <Text
                                    style={{
                                        width:'100%',
                                        textAlign:'center',
                                        fontSize:'14px',
                                        marginTop:'10px',
                                        marginBottom: selectedStatus ? '0px' : '10px'
                                    }}
                                    >
                                        {data.inicio ? `${new Date(data.inicio).toLocaleDateString('pt-BR')} - ${new Date(data.fim).toLocaleDateString('pt-BR')}` : ''}
                                    </Text> : <></>}
                                    {selectedStatus ? <Text
                                    style={{
                                        width:'100%',
                                        textAlign:'center',
                                        fontSize:'11px',
                                        marginBottom:'10px'
                                    }}
                                    >
                                        {selectedStatus ? ` (${selectedStatus})` : ''}
                                    </Text> : <></>}
                                    <View
                                    style={{
                                        display:'flex',
                                        justifyContent:'space-around',
                                        flexDirection:'row',
                                        marginTop:'20px',
                                        marginBottom:'20px'
                                    }}
                                    >
                                        <View>
                                            <Text
                                            style={{
                                                fontSize:'12px'
                                            }}
                                            >Pedidos</Text>
                                            <Text>{tableData.filter(({loja}) => data.loja === loja || !data.loja || data.loja === null).length}</Text>
                                        </View>
                                        <View>
                                            <Text
                                            style={{
                                                fontSize:'12px'
                                            }}>Valor</Text>
                                            <Text>{tableData.filter(({loja}) => data.loja === loja || !data.loja || data.loja === null).reduce((a,next) => (a += next.valor),0).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.table}>
                                        {/* Cabeçalho da tabela */}
                                        <View style={styles.tableRow}>
                                            {!data.loja || data.loja === null ? <Text style={styles.tableCellHeader}>Loja</Text> : <></>}
                                            <Text style={styles.tableCellHeader}>Pedido</Text>
                                            <Text style={styles.tableCellHeader}>Cliente</Text>
                                            {!selectedStatus ? <Text style={styles.tableCellHeader}>Status</Text> : <></>}
                                            <Text style={styles.tableCellHeader}>Data</Text>
                                            <Text style={styles.tableCellHeader}>Valor</Text>
                                        </View>

                                        {/* Linhas da tabela */}
                                        {tableData.filter(({loja}) => data.loja === loja || !data.loja || data.loja === null).map((item, index) => (
                                            <View key={index} style={{
                                                ...styles.tableRow,
                                                backgroundColor: index%2 ? '#ff00004f' : '#80808052'
                                            }}>
                                                {!data.loja || data.loja === null ? <Text style={styles.tableCell}>{item.loja}</Text> : <></>}
                                                <Text style={styles.tableCell}>{item.pedido}</Text>
                                                <Text style={styles.tableCell}>{item.cliente}</Text>
                                                {!selectedStatus ? <Text style={styles.tableCell}>{item.status}</Text> : <></>}
                                                <Text style={styles.tableCell}>{item.data}</Text>
                                                <Text style={styles.tableCell}>{parseFloat(item.valor).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Text>
                                            </View>
                                        ))}
                                    </View>
                                    <Text
                                    style={{
                                        width:'100%',
                                        textAlign:'center',
                                        marginTop:'20px',
                                        fontSize:'10px'
                                    }}
                                    >Gerado em {new Date().toLocaleString('pt-BR')}</Text>
                                </View>
                                    {/* {tableData.filter(({loja}) => data.loja === loja || !data.loja || data.loja === null).map((e,index) => (
                                        <View
                                        style={{
                                            height:'50px',
                                            width:'100%',
                                            backgroundColor: index%2 ? '#ff00004f' : '#80808052'
                                        }}
                                        >

                                        </View>
                                    ))} */}
                                {/* </View> */}
                            </Page>
                        </Document>
                    </PDFViewer>
                </div>
            </Modal>

            <Modal
            open={pdf2}
            onClose={() => setPDF2(false)}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <div 
                style={{ 
                    width: '90vw', 
                    height: '90vh',
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center'
                }}>
                    <div
                    style={{
                        width:'70vw',
                        justifyContent:'right',
                        display:'flex'
                    }}>
                    <Button
                    color='error'
                    variant='contained'
                    sx={{marginBottom:'10px'}}
                    onClick={() => setPDF2(false)}
                    >fechar</Button>
                    </div>
                    <PDFViewer style={{ width: '70vw', height: 'calc(90vh - 66px)' }}>
                        <Document
                        style={{
                            flexDirection: 'row'
                        }}
                        >
                            <Page 
                            size="A4" 
                            style={{
                                width:'100%',
                                height:'100%'
                            }}>
                                <View 
                                style={{
                                    padding:'10px'
                                }}>
                                    <Image
                                    style={{
                                        width:'100px'
                                    }}
                                    src={require('../../static/logo.png')}
                                    />
                                    <Text
                                    style={{
                                        width:'100%',
                                        textAlign:'center',
                                        fontSize:'16px',
                                        marginTop:'20px'
                                    }}
                                    >{order?.id} - {order?.cliente}</Text>
                                    <Text
                                    style={{
                                        width:'100%',
                                        textAlign:'center',
                                        fontSize:'14px',
                                        marginTop:'10px',
                                        marginBottom: selectedStatus ? '0px' : '10px'
                                    }}
                                    >
                                        {order?.data}
                                    </Text>
                                    {selectedStatus ? <Text
                                    style={{
                                        width:'100%',
                                        textAlign:'center',
                                        fontSize:'11px',
                                        marginBottom:'10px'
                                    }}
                                    >
                                        {selectedStatus ? ` (${selectedStatus})` : ''}
                                    </Text> : <></>}
                                    
                                    <View>
                                        <Text style={{fontSize:'10px'}}>Telefone: {order?.complete_purchase?.Checkout?.Client?.mobilePhone}</Text>
                                        <Text style={{fontSize:'10px'}}>CPF: {order?.complete_purchase?.Checkout?.Client.cpf}</Text>
                                        <Text style={{fontSize:'10px'}}>CEP: {order?.complete_purchase?.Checkout?.Client.cep}</Text>
                                        <Text style={{fontSize:'10px'}}>E-mail: {order?.complete_purchase?.Checkout?.Client.email}</Text>
                                    </View>

                                    <View
                                    style={{
                                        marginTop:'10px'
                                    }}
                                    >
                                        <Text style={{fontSize:'10px'}}>{order?.complete_purchase?.Checkout?.Client?.place}, {order?.complete_purchase?.Checkout?.Client?.number}, {order?.complete_purchase?.Checkout?.Client?.neighborhood}, {order?.complete_purchase?.Checkout?.Client?.region}</Text>
                                        <Text style={{fontSize:'10px'}}>{order?.complete_purchase?.Checkout?.Client?.reference}</Text>
                                    </View>

                                    <View
                                    style={{
                                        display:'flex',
                                        justifyContent:'space-around',
                                        flexDirection:'row',
                                        marginTop:'20px',
                                        marginBottom:'20px'
                                    }}
                                    >
                                        <View>
                                            <Text
                                            style={{
                                                fontSize:'12px'
                                            }}
                                            >{order?.complete_purchase?.Checkout?.DeliveryType}</Text>
                                            <Text
                                            style={{
                                                fontSize:'15px'
                                            }}>{order?.complete_purchase?.Checkout?.PaymentType}</Text>
                                        </View>
                                        <View>
                                            <Text
                                            style={{
                                                fontSize:'12px'
                                            }}>Valor</Text>
                                            <Text>{order?.valor?.toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.table}>
                                        {/* Cabeçalho da tabela */}
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellHeader}>EAN</Text>
                                            <Text style={styles.tableCellHeader}>Descricao</Text>
                                            <Text style={styles.tableCellHeader}>Categoria</Text>
                                            <Text style={styles.tableCellHeader}>Quantidade</Text>
                                            <Text style={styles.tableCellHeader}>Valor</Text>
                                        </View>

                                        {/* Linhas da tabela */}
                                        {order?.complete_purchase?.CheckoutDetails?.map((item, index) => (
                                            <View key={index} style={{
                                                ...styles.tableRow,
                                                backgroundColor: index%2 ? '#ff00004f' : '#80808052'
                                            }}>
                                                <Text style={styles.tableCell}>{item.GTIN}</Text>
                                                <Text style={styles.tableCell}>{item.Description}</Text>
                                                <Text style={styles.tableCell}>{item.ProductCategory}</Text>
                                                <Text style={styles.tableCell}>{parseFloat(item.Quantity?.toFixed(2))}</Text>
                                                <Text style={styles.tableCell}>{parseFloat(item.Price).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Text>
                                            </View>
                                        ))}
                                    </View>
                                    <Text
                                    style={{
                                        width:'100%',
                                        textAlign:'center',
                                        marginTop:'20px',
                                        fontSize:'10px'
                                    }}
                                    >Gerado em {new Date().toLocaleString('pt-BR')}</Text>
                                </View>
                                    {/* {tableData.filter(({loja}) => data.loja === loja || !data.loja || data.loja === null).map((e,index) => (
                                        <View
                                        style={{
                                            height:'50px',
                                            width:'100%',
                                            backgroundColor: index%2 ? '#ff00004f' : '#80808052'
                                        }}
                                        >

                                        </View>
                                    ))} */}
                                {/* </View> */}
                            </Page>
                        </Document>
                    </PDFViewer>
                </div>
            </Modal>

            {order &&
            <Modal
            open={order}
            onClose={() => {
                setOrder(null)
                setRelatorio(false)
            }}
            sx={{
                width:'100%',
                height:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <Box
                sx={{
                    margin:'20px 20px 0px 20px',
                    backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                    padding:'10px',
                    borderRadius:'5px',
                    width:'70%',
                    height:'90%',
                    color:'text.primary',
                    overflowY:'auto'
                }}>
                    <div>
                        <Typography sx={{fontSize:'25px',width:'100%',textAlign:'center'}}>{order.pedido} - {order.cliente}</Typography>
                        <Typography sx={{fontSize:'15px',width:'100%',textAlign:'center',opacity:0.8}}>{order.data}</Typography>
                    </div>
                    <div style={{marginTop:'20px'}}>
                        <Typography sx={{fontSize:'18px',width:'100%',opacity:0.8}}>Loja: {order.codloja} - {order.loja}</Typography>
                        <Typography sx={{fontSize:'18px',width:'100%',opacity:0.8}}>Pagamento: {order.pagamento}</Typography>
                        <div
                        style={{
                            display:'flex'
                        }}>
                            <Typography sx={{fontSize:'18px',width:'max-content',opacity:0.8}}>Telefone: {order.complete_purchase.Checkout.Client.mobilePhone}</Typography>
                            <ButtonBase onClick={() => window.open(`tel:${order.complete_purchase.Checkout.Client.mobilePhone}`)}>
                                <LocalPhoneIcon sx={{marginLeft:'10px'}} />
                            </ButtonBase>
                            <ButtonBase onClick={() => window.open(`https://api.whatsapp.com/send?phone=${order.complete_purchase.Checkout.Client.mobilePhone}`)}>
                                <WhatsAppIcon sx={{marginLeft:'10px'}}/>
                            </ButtonBase>
                        </div>
                        <Typography sx={{fontSize:'18px',width:'100%',opacity:0.8}}>CPF: {order.complete_purchase.Checkout.Client.cpf}</Typography>
                        <Typography sx={{fontSize:'18px',width:'100%',opacity:0.8}}>CEP: {order.complete_purchase.Checkout.Client.cep}</Typography>
                        <ButtonBase
                        onClick={() => window.open(`mailto:${order.complete_purchase.Checkout.Client.email}`)}>
                            <Typography sx={{fontSize:'18px',width:'100%',opacity:0.8}}>E-mail: {order.complete_purchase.Checkout.Client.email}</Typography>
                            <EmailIcon sx={{marginLeft:'10px'}} />
                        </ButtonBase>

                        <ButtonBase
                        onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${order.complete_purchase.Checkout.Client.lat}%2C${order.complete_purchase.Checkout.Client.lon}`)}
                        sx={{
                            flexDirection:'column',
                            width:'100%',
                            marginTop:'20px'
                        }}>
                            <Typography sx={{fontSize:'18px',width:'100%'}}>{order.complete_purchase.Checkout.Client.place}, {order.complete_purchase.Checkout.Client.number}, {order.complete_purchase.Checkout.Client.neighborhood}, {order.complete_purchase.Checkout.Client.region}</Typography>
                            <Typography sx={{fontSize:'18px',width:'100%'}}>{order.complete_purchase.Checkout.Client.reference}</Typography>
                            <MapIcon />
                        </ButtonBase>
                    </div>
                    <div
                    style={{
                        width:'100%',
                        marginTop:'10px'
                    }}>
                        {!relatorio && <Popover
                        open={quantityAnchor !== null}
                        anchorEl={quantityAnchor}
                        onClose={() => setQuantityAnchor(null)}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                        }}
                        sx={{
                            flexDirection:'column',
                            width:'300px'
                        }}
                        >
                            <div
                            style={{
                                margin:'10px'
                            }}>
                                <TextField 
                                onChange={({target}) => setQuantity(order.complete_purchase.CheckoutDetails[quantityIndex].Fraction ? float(target.value) : integer(target.value))}
                                value={quantity}
                                label='Quantidade'
                                />
                                <Button onClick={save} fullWidth variant='outlined'>Salvar</Button>
                                <Button fullWidth variant='contained' color='error'>cancelar</Button>
                            </div>
                        </Popover>}
                        {!relatorio && <table
                        style={{
                            width:'100%',
                            textAlign:"center",
                            border: '1px solid #f44646',
                            borderRadius:'5px'
                        }}>
                            <tr style={{backgroundColor:'rgba(255,0,0,0.1)'}}>
                                <td><Typography>Nome</Typography></td>
                                <td><Typography>GTIN</Typography></td>
                                <td><Typography>Preço</Typography></td>
                                <td><Typography>Quantidade</Typography></td>
                                <td><Typography>Estoque</Typography></td>
                                <td><Typography>Cod. interno</Typography></td>
                            </tr>
                            {order.complete_purchase.CheckoutDetails?.map((e,index) => (
                            <tr
                            style={{ backgroundColor:quantityIndex === index ? '#f44646' : (index % 2 === 1 ? 'rgba(255,0,0,0.1)' : 'rgba(0,0,0,0)') }}>
                                <td><Typography>{e.Description}</Typography></td>
                                <td><Typography>{e.GTIN}</Typography></td>
                                <td><Typography>{parseFloat(e.FinalPrice).toLocaleString('pt-BR',{currency:'BRL',style:'currency'})}</Typography></td>
                                <td onClick={({currentTarget}) => {
                                    setQuantityAnchor(currentTarget)
                                    setQuantityIndex(index)
                                }} style={{cursor:'pointer'}}>
                                    <Typography>{e.Quantity?.toFixed(2)}</Typography>
                                    <EditIcon sx={{marginLeft:'10px'}} />
                                </td>
                                <td><Typography>{e.Stock?.toFixed(2)}</Typography></td>
                                <td><Typography>{e.SKU}</Typography></td>
                            </tr>
                            ))}
                        </table>}

                        {relatorio &&
                        <Table
                        list={order.complete_purchase.CheckoutDetails?.map(e => ({
                            Nome: e.Description,
                            GTIN: e.GTIN,
                            Preço: e.FinalPrice.toLocaleString('pt-BR',{currency:'BRL',style:'currency'}),
                            Quantidade : e.Quantity?.toFixed(2),
                            Estoque: e.Stock?.toFixed(2),
                            'Cod. interno': e.SKU
                        }))} 
                        header={[]}
                        notVisible={[]}
                        type={{}}
                        lineClick={e => {
                        }}
                        columnClick={()=>{}}
                        onChange={e => {}}
                        changeAll={items => {}}
                        page={true}
                        sort={true}
                        filter={true}
                        check={false}
                        options={true}
                        elementName={`Relatório_de_itens_${order.pedido}_${order.cliente}_${Date.now()}`}
                        rowsPerPage={5}
                        extra={
                            <>
                            <Button
                            color='error'
                            endIcon={<PictureAsPdfIcon />}
                            onClick={() => setPDF2(true)}
                            >PDF</Button>
                            </>
                        }
                        />}
                    </div>
                    {!relatorio && <Button onClick={send} color='error' fullWidth variant='contained' sx={{marginTop:'10px'}}>salvar</Button>}
                    <Button onClick={() => setRelatorio(!relatorio)} variant='outlined' fullWidth sx={{marginTop:'10px'}}>{relatorio ? 'ver como edição' : 'ver como relatório'}</Button>
                </Box>
            </Modal>}

            <Box
            sx={{
                margin:'20px 20px 0px 20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:'10px',
                borderRadius:'5px',
                width:'100%'
            }}>
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between'
                }}>
                    <div
                    style={{
                        display:'flex'
                    }}>
                        <FormControl sx={{marginRight:'10px', minWidth:'220px'}}>
                            <InputLabel>Loja</InputLabel>
                            <Select
                                value={data.loja}
                                onChange={({target}) => setData({...data, loja: target.value})}
                            >
                                <MenuItem value={null}>{'Todas'}</MenuItem>
                                {company.map(e => (<MenuItem value={e.name}>{e.name}</MenuItem>))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{marginRight:'10px', minWidth:'220px'}}>
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={selectedStatus}
                                onChange={({target}) => setSelectedStatus(target.value)}
                            >
                                {status.map(e => (<MenuItem value={e.status}>{e.status}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </div>

                    <div
                    style={{
                        display:'flex'
                    }}>
                    <LocalizationProvider adapterLocale={'pt-br'} dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                        label="Data inicial"
                        value={data.inicio}
                        onChange={(newValue) => {
                            if (data.fim && newValue > data.fim) return toast.error('Data inicial não pode ser maior que a final')
                            setData({...data, inicio: newValue})
                            console.log(newValue)
                        }}
                        renderInput={(params) => <TextField {...params}  sx={{marginRight:'10px',width:'150px'}}/>}
                        views={['year','month','day']}
                        />

                        <MobileDatePicker
                        label="Data final"
                        value={data.fim}
                        onChange={(newValue) => {
                            if (data.inicio && newValue < data.inicio) return toast.error('Data final não pode ser menor que a inicial')
                            setData({...data, fim: newValue})
                        }}
                        renderInput={(params) => <TextField {...params}  sx={{marginRight:'10px',width:'150px'}}/>}
                        views={['year','month','day']}
                        />
                    </LocalizationProvider>
                    <Button 
                    onClick={() => {
                        setSelectedStatus('')
                        setData({inicio:'',fim:''})
                    }}
                    variant='outlined' color='error'>limpar</Button>
                    </div>
                </div>
                <Table
                list={tableData.filter(({loja}) => data.loja === loja || !data.loja || data.loja === null)} 
                header={[]}
                notVisible={['id']}
                type={{valor:{type:'money'}}}
                lineClick={e => {
                    setLoading(true)
                    axios
                    .get(`${api}/api/2020-06/order/${e.tableValue.id}`,{
                        headers: {
                            'lookas-api-apptoken': user.access_token,
                            'lookas-api-appkey':'7vUxkJrtS1iBwFS4rZveNWnRDACemCOH'
                        }
                    })
                    .then(response => setOrder({...e.tableValue,...response.data[0]}))
                    .catch(err => console.log(err))
                    .finally(() => setLoading(false))
                }}
                columnClick={()=>{}}
                onChange={e => {}}
                changeAll={items => {}}
                page={true}
                sort={true}
                filter={true}
                check={false}
                options={true}
                elementName={`Relatório_de_vendas_${Date.now()}`}
                extra={
                    <>
                    <Button
                    color='error'
                    endIcon={<PictureAsPdfIcon />}
                    onClick={() => setPDF(true)}
                    >PDF</Button>
                    </>
                }
                rowsPerPage={5}/>
            </Box>
        </div>
    )
}