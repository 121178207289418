import { useState, useEffect, useContext } from 'react'
import {
    Typography,
    Box,
    Button,
    TextField,
    Tooltip as MuiTooltip,
    CircularProgress,
    ButtonBase,
    Modal
} from '@mui/material'

import { useTheme } from '@mui/material/styles';

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import axios from 'axios';
import { api } from '../../utils/defaults';
import { LoadingContext, UserContext } from '../../context/handleContext';
import logout from '../../utils/logout';
import useInterval from 'use-interval';

import Table from '../table'

export default function Index () {
    const [vendas, setVendas] = useState([])
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [show, setShow] = useState({pedido:true,valor:true})
    const [days, setDays] = useState(30)
    const [status, setStatus] = useState({})
    const [newClients, setNewClients] = useState([])
    const [loadClients, setLoadClients] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    
    const [tableData, setTableData] = useState(undefined) 

    const update = () => {
        setLoading(true)
        setLoadClients(true)
        setLoadingData(true)
        axios
        .get(`${api}/api/manage/dashboard/order/daysPerStore?days=${days===0?30:days}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setVendas(response.data))
        .catch(err => {
            if(err.response.status===401) logout()
        })
        .finally(() => setLoading(false))

        axios
        .get(`${api}/api/manage/dashboard/order/status`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setStatus({
            canceled : response.data.filter(e => (e.status==='canceled')).length===0 ? 0 : response.data.filter(e => (e.status==='canceled'))[0].count,
            handling : response.data.filter(e => (e.status==='ready-for-handling')).length===0 ? 0 : response.data.filter(e => (e.status==='ready-for-handling'))[0].count,
            imported : response.data.filter(e => (e.status==='imported')).length===0 ? 0 : response.data.filter(e => (e.status==='imported'))[0].count,
            invoiced : response.data.filter(e => (e.status==='invoiced')).length===0 ? 0 : response.data.filter(e => (e.status==='invoiced'))[0].count
        }))
        .catch(err => {
            if(err.response.status===401) logout()
        })
        .finally(() => setLoadingData(false))

        axios
        .get(`${api}/api/manage/newClients`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => setNewClients(response.data))
        .catch(err => {
            if(err.response.status===401) logout()
        })
        .finally(() => setLoadClients(false))
    }

    useEffect(() => update(),[])

    const theme = useTheme()

    useInterval(() => update(),300000)

    return (
        <div 
        style={{
            display:'flex',
            justifyContent:'space-around',
            flexWrap:'wrap',
            overflowY:'auto',
            height:'calc(100% - 64px)',
            marginBottom:'20px',
            flexWrap:'wrap'
        }}>
            <Modal 
            open={tableData}
            onClose={() => setTableData(false)}
            sx={{
                width:'100%',
                height:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}>
                <Box
                sx={{
                    margin:'20px 20px 0px 20px',
                    backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                    padding:'10px',
                    borderRadius:'5px',
                    overflowY:'auto',
                    maxHeight:'95vh'
                }}>
                    <Table
                    list={tableData} 
                    header={[]}
                    notVisible={[]}
                    type={{valor:{type:'money'}}}
                    lineClick={e => {}}
                    columnClick={()=>{}}
                    onChange={e => {}}
                    changeAll={items => {}}
                    page={true}
                    sort={true}
                    filter={true}
                    check={false}
                    options={true}
                    elementName={Date.now()}
                    rowsPerPage={5}/>
                </Box>
            </Modal>
            <div 
            style={{
                width:'100%',
                display: 'flex',
                justifyContent:'space-around',
                marginTop:'20px',
                flexWrap:'wrap'
            }}>
                <ButtonBase 
                onClick={() => {
                    setLoading(true)
                    axios
                    .get(`${api}/api/manage/order`,{
                        headers: {
                            Authorization: user.token
                        }
                    })
                    .then(response => setTableData(response.data.map(e => ({...e, data : new Date(e.data).toLocaleDateString('pt-BR')}))))
                    .catch(err => {
                        if(err.response.status===401) logout()
                    })
                    .finally(() => setLoading(false))
                }}
                sx={{height:'fit-content'}}>
                <div 
                style={{
                    backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                    height:'fit-content',
                    padding:'20px 40px',
                    borderRadius:'5px',
                    width:'200px',
                    margin:'10px'
                }}>
                    <div>
                        <Typography sx={{fontSize:'45px',fontWeight:'bold'}}>{
                            loadingData ? <CircularProgress /> : 
                            (Number.isNaN(parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)) ? 
                            0 :
                            parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced))
                        }</Typography>
                    </div>
                    <div>
                        <Typography sx={{}}>Total dos pedidos</Typography>
                    </div>
                    <div style={{height:'3px',width:'100%',backgroundColor:'#2a9ede',borderRadius:'3px'}}></div>
                </div>
                </ButtonBase>

                <ButtonBase 
                onClick={() => {
                    setLoading(true)
                    axios
                    .get(`${api}/api/manage/order?status=imported`,{
                        headers: {
                            Authorization: user.token
                        }
                    })
                    .then(response => setTableData(response.data.map(e => ({...e, data : new Date(e.data).toLocaleDateString('pt-BR')}))))
                    .catch(err => {
                        if(err.response.status===401) logout()
                    })
                    .finally(() => setLoading(false))
                }}
                sx={{height:'fit-content'}}>
                <div 
                style={{
                    backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                    height:'fit-content',
                    padding:'20px 40px',
                    borderRadius:'5px',
                    width:'200px',
                    margin:'10px'
                }}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <Typography sx={{fontSize:'45px',fontWeight:'bold'}}>{
                            loadingData ? <CircularProgress /> : 
                            (status.imported ? status.imported : 0)
                        }</Typography>
                        <Typography 
                        sx={{
                            fontSize:'15px',
                            fontWeight:'',
                            padding:'5px 20px',
                            borderRadius:'510px',
                            backgroundColor:theme.palette.mode==='dark'?'#121212':'#ffffff',
                            height:'fit-content',
                            textAlign:"center"
                        }}
                        >{(Number.isNaN(100*(parseInt(status.imported)
                            /(Number.isNaN(parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)) ? 0 :
                            parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)))) ? 0 : 
                            100*(parseInt(status.imported)
                            /(Number.isNaN(parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)) ? 0 :
                            parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)))).toFixed(2)}%</Typography>
                    </div>
                    <div>
                        <Typography sx={{}}>Pedidos Concluídos</Typography>
                    </div>
                    <div style={{height:'3px',width:'100%',backgroundColor:'#00af9f',borderRadius:'3px'}}></div>
                </div>
                </ButtonBase>

                <ButtonBase 
                onClick={() => {
                    setLoading(true)
                    axios
                    .get(`${api}/api/manage/order?status=canceled`,{
                        headers: {
                            Authorization: user.token
                        }
                    })
                    .then(response => setTableData(response.data.map(e => ({...e, data : new Date(e.data).toLocaleDateString('pt-BR')}))))
                    .catch(err => {
                        if(err.response.status===401) logout()
                    })
                    .finally(() => setLoading(false))
                }}
                sx={{height:'fit-content'}}>
                <div 
                style={{
                    backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                    height:'fit-content',
                    padding:'20px 40px',
                    borderRadius:'5px',
                    width:'200px',
                    margin:'10px'
                }}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <Typography sx={{fontSize:'45px',fontWeight:'bold'}}>{
                            loadingData ? <CircularProgress /> : 
                            (status.canceled ? status.canceled : 0)
                        }</Typography>
                        <Typography 
                        sx={{
                            fontSize:'15px',
                            fontWeight:'',
                            padding:'5px 20px',
                            borderRadius:'510px',
                            backgroundColor:theme.palette.mode==='dark'?'#121212':'#ffffff',
                            height:'fit-content',
                            textAlign:"center"
                        }}
                        >{(Number.isNaN(100*(parseInt(status.canceled)
                            /(Number.isNaN(parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)) ? 0 :
                            parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)))) ? 0 :
                            100*(parseInt(status.canceled)
                            /(Number.isNaN(parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)) ? 0 :
                            parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)))).toFixed(2)}%</Typography>
                    </div>
                    <div>
                        <Typography sx={{}}>Pedidos cancelados</Typography>
                    </div>
                    <div style={{height:'3px',width:'100%',backgroundColor:'#d80021',borderRadius:'3px'}}></div>
                </div>
                </ButtonBase>

                <MuiTooltip title='Novos clientes nos últimos 30 dias'>
                <ButtonBase sx={{height:'fit-content'}} onClick={() => setTableData(newClients.map(e => ({...e, data : new Date(e.data).toLocaleDateString('pt-BR')})))}>
                <div 
                style={{
                    backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                    height:'fit-content',
                    padding:'20px 40px',
                    borderRadius:'5px',
                    width:'200px',
                    margin:'10px'
                }}>
                    <div>
                        <Typography sx={{fontSize:'45px',fontWeight:'bold'}}>{ loadClients ? <CircularProgress /> : newClients.length }</Typography>
                    </div>
                    <div>
                        <Typography sx={{}}>Clientes novos</Typography>
                    </div>
                    <div style={{height:'3px',width:'100%',backgroundColor:'#cfcfcf',borderRadius:'3px'}}></div>
                </div>
                </ButtonBase>
                </MuiTooltip>

                <ButtonBase 
                onClick={() => {
                    setLoading(true)
                    axios
                    .get(`${api}/api/manage/order?status=ready-for-handling`,{
                        headers: {
                            Authorization: user.token
                        }
                    })
                    .then(response => setTableData(response.data.map(e => ({...e, data : new Date(e.data).toLocaleDateString('pt-BR')}))))
                    .catch(err => {
                        if(err.response.status===401) logout()
                    })
                    .finally(() => setLoading(false))
                }}
                sx={{height:'fit-content'}}>
                <div 
                style={{
                    backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                    height:'fit-content',
                    padding:'20px 40px',
                    borderRadius:'5px',
                    width:'200px',
                    margin:'10px'
                }}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <Typography sx={{fontSize:'45px',fontWeight:'bold'}}>{
                            loadingData ? <CircularProgress /> :
                            (status.handling ? status.handling : 0)
                        }</Typography>
                        <Typography 
                        sx={{
                            fontSize:'15px',
                            fontWeight:'',
                            padding:'5px 20px',
                            borderRadius:'510px',
                            backgroundColor:theme.palette.mode==='dark'?'#121212':'#ffffff',
                            height:'fit-content',
                            textAlign:"center"
                        }}
                        >{(Number.isNaN(100*(parseInt(status.handling)
                            /(Number.isNaN(parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)) ? 0 :
                            parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)))) ? 0 : 
                            100*(parseInt(status.handling)
                            /(Number.isNaN(parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)) ? 0 :
                            parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)))).toFixed(2)}%</Typography>
                    </div>
                    <div>
                        <Typography sx={{}}>Aguardando separação</Typography>
                    </div>
                    <div style={{height:'3px',width:'100%',backgroundColor:'yellow',borderRadius:'3px'}}></div>
                </div>
                </ButtonBase>
                
                <ButtonBase 
                onClick={() => {
                    setLoading(true)
                    axios
                    .get(`${api}/api/manage/order?status=invoiced`,{
                        headers: {
                            Authorization: user.token
                        }
                    })
                    .then(response => setTableData(response.data.map(e => ({...e, data : new Date(e.data).toLocaleDateString('pt-BR')}))))
                    .catch(err => {
                        if(err.response.status===401) logout()
                    })
                    .finally(() => setLoading(false))
                }}
                sx={{height:'fit-content'}}>
                <div 
                style={{
                    backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                    height:'fit-content',
                    padding:'20px 40px',
                    borderRadius:'5px',
                    width:'200px',
                    margin:'10px'
                }}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <Typography sx={{fontSize:'45px',fontWeight:'bold'}}>{
                            loadingData ? <CircularProgress /> :
                            (status.invoiced ? status.invoiced : 0)
                        }</Typography>
                        <Typography 
                        sx={{
                            fontSize:'15px',
                            fontWeight:'',
                            padding:'5px 20px',
                            borderRadius:'510px',
                            backgroundColor:theme.palette.mode==='dark'?'#121212':'#ffffff',
                            height:'fit-content',
                            textAlign:"center"
                        }}
                        >{(Number.isNaN(100*(parseInt(status.invoiced)
                            /(Number.isNaN(parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)) ? 0 :
                            parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)))) ? 0 : 
                            100*(parseInt(status.invoiced)
                            /(Number.isNaN(parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)) ? 0 :
                            parseInt(status.canceled) + parseInt(status.handling) + parseInt(status.imported) + parseInt(status.invoiced)))).toFixed(2)}%</Typography>
                    </div>
                    <div>
                        <Typography sx={{}}>Separados</Typography>
                    </div>
                    <div style={{height:'3px',width:'100%',backgroundColor:'#000',borderRadius:'3px'}}></div>
                </div>
                </ButtonBase>

            </div>
            <div 
            style={{
                width:'100%',
                display: 'flex',
                justifyContent:'space-around',
                height:'400px',
                flexDirection:"column"
            }}>
                <Box sx={{margin:'20px'}}>
                    <Button 
                    onClick={() => setShow({
                        pedido: !show.pedido,
                        valor: (!show.pedido===false && !show.valor) ? true : show.valor
                    })}
                    sx={{
                        margin:'0px 10px 0px 10px',
                        color:show.pedido ? '#000' : '#8884d8',
                        borderColor:'#8884d8',
                        backgroundColor:show.pedido && '#8884d8',
                        height:'56px'
                    }} variant={show.pedido ? 'contained' : 'outlined'}>pedido</Button>
                    
                    <Button 
                    onClick={() => setShow({
                        pedido: (!show.valor===false && !show.pedido) ? true : show.pedido,
                        valor: !show.valor
                    })}
                    sx={{
                        margin:'0px 10px 0px 10px',
                        color:show.valor ? '#000' : '#82ca9d',
                        borderColor:'#82ca9d',
                        backgroundColor:show.valor && '#82ca9d',
                        height:'56px'
                    }} variant={show.valor ? 'contained' : 'outlined'}>valor</Button>

                    <TextField 
                    onChange={({target}) => setDays(
                        target.value==='' ? 0 :
                        (Number.isNaN(parseInt(target.value.replace('.','').replace(',','').replace('-',''))) ? 
                        days :
                        parseInt(target.value.replace('.','').replace(',','').replace('-','')))
                    )}
                    onBlur={update}
                    value={days}
                    label='Dias'
                    sx={{width:'80px'}}
                    />
                </Box>
                <Typography sx={{marginLeft:'20px',fontWeight:'bold'}}>Vendas X Valor por filial</Typography>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                    width={500}
                    height={200}
                    data={vendas.map(e => ({vendas:e.pedidos,valor:e.valor,name:e.name}))}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {show.pedido && <Bar dataKey="vendas" fill="#8884d8" />}
                    {show.valor && <Bar dataKey="valor" fill="#82ca9d" />}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

{/* <Table
                list={mix.map((e,index) => ({...e,tableChecked:selected.indexOf(index)===-1?false:true}))} 
                header={[]}
                notVisible={['codigoCategoria']}
                type={{
                    preco:{type:'money'},
                    precoPromocional:{type:'money'},
                    promocao:{
                        type:'button',
                        onClick: (e) => {},
                        cases : {
                            1:{backgroundColor:'#28a745',color:'#fff',replace:'Sim'},
                            0:{backgroundColor:'#dc3545',color:'#fff',replace:'Não'}
                        }
                    }
                }}
                lineClick={e => {
                    if (selected.indexOf(e._nativeIndex)!==-1) {
                        let dt = selected
                        dt.splice(selected.indexOf(e._nativeIndex),1)
                        setSelected(dt)
                    } else {
                        setSelected([...selected,e._nativeIndex])
                    }
                    forceUpdate()
                }}
                columnClick={()=>{}}
                onChange={e => {}}
                changeAll={items => {
                    let quant = items.reduce((acumulator,value) => acumulator + (selected.indexOf(value._nativeIndex)===-1?0:1) ,0)
                    let sel = selected

                    items.map(value => {
                        let index = selected.indexOf(value._nativeIndex)
                        if (quant === items.length) { // desmarca
                            if (index !== -1) {
                                sel.splice(index,1)
                            }
                        } else { // marca
                            if (index===-1) {
                                sel.push(value._nativeIndex)
                            }
                        }
                    })
                    setSelected(sel)
                    forceUpdate()
                }}
                page={true}
                sort={true}
                filter={true}
                check={true}
                options={true}
                elementName="relatorio_tabela"
                rowsPerPage={5}/> */}