import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { api } from '../../utils/defaults'

import {
    Box,
    Modal,
    TextField,
    Button,
    Tooltip,
    Typography,
    FormControl,
    Select,
    MenuItem,
    InputLabel
} from '@mui/material'

import { useTheme } from '@mui/material/styles';

import {
    UserContext,
    LoadingContext,
    CompanyContext
} from '../../context/handleContext'

import useInterval from 'use-interval'

import Table from '../table'
import logout from '../../utils/logout'

export default function Index () {
    const [loading, setLoading] = useContext(LoadingContext)
    const [user, setUser] = useContext(UserContext)
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(undefined)
    const [company, setCompany] = useContext(CompanyContext)
    const [error, setError] = useState('')
    const theme = useTheme()

    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/user`, {
            headers: {
                Authorization : user.token
            }
        })
        .then(response => setUsers(response.data))
        .catch(err => {
            if(err.response.status===401) logout()
        })
        .finally(() => setLoading(false))
    }

    useEffect(() => update(), [])
    useInterval(() => update(), 300000)

    return (
        <div  
        style={{
            display:'flex',
            justifyContent:'space-around',
            flexWrap:'wrap',
            overflowY:'auto',
            height:'calc(100% - 64px)',
            marginBottom:'20px',
            overflowX:'auto'
        }}>
            {selectedUser &&
            <Modal
            onClose={() => setSelectedUser(undefined)}
            open={selectedUser !== undefined}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
            >
                <Box 
                sx={{
                    margin:'20px 20px 0px 20px',
                    backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                    padding:'10px',
                    borderRadius:'5px',
                    display:'flex',
                    flexWrap:'wrap',
                    maxWidth:'450px',
                    justifyContent:'center'
                }}>
                    <Typography sx={{width:'100%',color:'red', textAlign:'center'}}>{error}</Typography>
                    <TextField 
                    sx={{margin:'10px',width:'100%'}}
                    value={selectedUser.username}
                    onChange={({target}) => setSelectedUser({...selectedUser,username:target.value})}
                    label='Usuário'
                    />
                    <TextField 
                    sx={{margin:'10px',width:'100%'}}
                    value={selectedUser.fullname}
                    onChange={({target}) => setSelectedUser({...selectedUser,fullname:target.value})}
                    label='Nome completo'
                    />
                    <TextField 
                    sx={{margin:'10px',width:'100%'}}
                    value={selectedUser.password}
                    onChange={({target}) => setSelectedUser({...selectedUser,password:target.value})}
                    label='Senha'
                    type='password'
                    />
                    <TextField 
                    sx={{margin:'10px',width:'100%'}}
                    value={selectedUser.email}
                    onChange={({target}) => setSelectedUser({...selectedUser,email:target.value})}
                    label='Email'
                    />
                    <TextField 
                    sx={{margin:'10px',width:'100%'}}
                    value={selectedUser.phone}
                    onChange={({target}) => setSelectedUser({...selectedUser,phone:target.value})}
                    label='Telefone'
                    />

                    <FormControl sx={{margin:'10px',width:'100%'}}>
                        <InputLabel>Empresa</InputLabel>
                        <Select
                        value={selectedUser.store_id}
                        onChange={({target}) => setSelectedUser({...selectedUser,store_id:target.value})}
                        >
                            {company.map(e => (<MenuItem value={e.id}>{e.id === e.parent_id ? `${e.name} (matriz)` : e.name}</MenuItem>))}
                        </Select>
                    </FormControl>

                    <div 
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-around',
                        alignItems:'center',
                        flexWrap:'wrap'
                    }}>
                        <Tooltip title='Permite ativar as lojas'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,active_store:!selectedUser.active_store})}
                            sx={{margin:'1px', width:'170px'}}
                            variant={selectedUser.active_store ? 'contained' : 'outlined'}>
                                Ativar loja
                            </Button>
                        </Tooltip>

                        <Tooltip title='Permite Gerenciar as lojas'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,crud_store:!selectedUser.crud_store})}
                            sx={{margin:'5px', width:'170px'}}
                            variant={selectedUser.crud_store ? 'contained' : 'outlined'}>
                                Gerenciar loja
                            </Button>
                        </Tooltip>

                        <Tooltip title='Permite realizar estorno'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,payment_reversal:!selectedUser.payment_reversal})}
                            sx={{margin:'5px', width:'170px'}}
                            variant={selectedUser.payment_reversal ? 'contained' : 'outlined'}>
                                Estorno
                            </Button>
                        </Tooltip>

                        <Tooltip title='Permite editar e separar pedidos'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,manage_purchase:!selectedUser.manage_purchase})}
                            sx={{margin:'5px', width:'170px'}}
                            variant={selectedUser.manage_purchase ? 'contained' : 'outlined'}>
                                Editar pedido
                            </Button>
                        </Tooltip>

                        <Tooltip title='Aplica permissao em todas as lojas'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,all_store:!selectedUser.all_store})}
                            sx={{margin:'5px', width:'170px'}}
                            variant={selectedUser.all_store ? 'contained' : 'outlined'}>
                                Todas lojas
                            </Button>
                        </Tooltip>

                        <Tooltip title='Ativa / inativa usuário'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,active:!selectedUser.active})}
                            sx={{margin:'5px', width:'170px'}}
                            variant={selectedUser.active ? 'contained' : 'outlined'}>
                                Ativo
                            </Button>
                        </Tooltip>

                        <Tooltip title='Permite tirar relatório financeiro'>
                            <Button 
                            onClick={() => setSelectedUser({...selectedUser,financial_report:!selectedUser.financial_report})}
                            sx={{margin:'5px'}}
                            variant={selectedUser.financial_report ? 'contained' : 'outlined'}>
                                Relatório financeiro
                            </Button>
                        </Tooltip>

                    </div>
                    <Button 
                    onClick={() => {
                        setLoading(true)
                        if (selectedUser.id_user) {
                            axios
                            .put(`${api}/api/manage/user`,selectedUser,{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                setSelectedUser(undefined)
                                setError('')
                                update()
                            })
                            .catch(err => setError(err.response.data))
                            .finally(() => setLoading(false))
                        } else {
                            axios
                            .post(`${api}/api/manage/user`,selectedUser,{
                                headers: {
                                    Authorization: user.token
                                }
                            })
                            .then(response => {
                                setSelectedUser(undefined)
                                setError('')
                                update()
                            })
                            .catch(err => setError(err.response.data))
                            .finally(() => setLoading(false))
                        }
                    }}
                    sx={{marginTop:'20px'}} fullWidth variant='contained' color='success'>
                        Salvar
                    </Button>
                </Box>
            </Modal>}
            
            <Box
            sx={{
                margin:'20px 20px 0px 20px',
                backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:'10px',
                borderRadius:'5px',
                height:'calc(100% - 90px)',
                width:'100%'
            }}>
                <div style={{width:'100%',display:'flex',justifyContent:"center"}}><Button onClick={() => setSelectedUser({})}>Criar usuário</Button></div>
                <Table
                list={users.map(e => ({
                    usuario: e.username,
                    email: e.email,
                    loja: e.store_id,
                    telefone: e.phone,
                    ativo: e.active
                }))} 
                header={[]}
                notVisible={[]}
                type={{}}
                lineClick={e => setSelectedUser(users.find(j => (j.username===e.tableValue.usuario)) ? {...users.find(j => (j.username===e.tableValue.usuario)),password:undefined} : undefined)}
                columnClick={()=>{}}
                onChange={e => {}}
                changeAll={items => {}}
                page={true}
                sort={true}
                filter={true}
                check={false}
                options={true}
                elementName={Date.now()}
                rowsPerPage={5}/>
            </Box>
        </div>
    )
}