import React, { useContext, useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import { ThemeProvider, createTheme } from '@mui/material/styles'

import { 
  ColorModeContext,
  HandleScreensContext,
  PreferencesContext,
  UserContext,
  LoadingContext,
  CompanyContext
} from './context/handleContext'

import {
  Modal,
  CircularProgress
} from '@mui/material'

import usePersistedState from './utils/usePersistedState'

import * as Components from './components/handleComponents'
import Menu from './components/menu'

import axios from 'axios'

import {
  api
} from './utils/defaults'
import logout from './utils/logout'

import toast, { Toaster } from 'react-hot-toast';

const key = new Date().toLocaleDateString('pt-BR')

export default function App (props) {
    const [mode, setMode]                 = usePersistedState('_theme','dark')
    const [handleScreen, setHandleScreen] = useState({})
    const [loading, setLoading]           = useState(false)
    const [preferences, setPreferences]   = usePersistedState('_preferences',{})
    const [user, setUser]                 = usePersistedState('data',{},key)
    const [company, setCompany]           = usePersistedState('company',[])

    const colorMode = React.useMemo(() => ({toggleColorMode: () => {setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))},}),[],)
    const theme = React.useMemo(() =>createTheme({palette: {mode,},}),[mode],)
    
    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
            <PreferencesContext.Provider value={[preferences, setPreferences]}>
            <HandleScreensContext.Provider value={[handleScreen, setHandleScreen]}>
            <LoadingContext.Provider value={[loading, setLoading]}>
            <UserContext.Provider value={[user, setUser]}>
            <CompanyContext.Provider value={[company, setCompany]}>
            <Modal open={loading} sx={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <CircularProgress color='secondary' />
                </Modal>
                <Toaster />
                <BrowserRouter>
                    <Routes>

                        <Route 
                        exact 
                        path="/login" 
                        element={<Components.Login />} />

                        <Route 
                        exact 
                        path="/reset-password" 
                        element={<Components.Forget />} />

                        <Route 
                        exact 
                        path="/" 
                        element={<Security_shield><Components.Home /></Security_shield>} />

                        <Route 
                        exact 
                        path="/dashboard" 
                        element={<Security_shield><Components.Dashboard /></Security_shield>} />

                        <Route 
                        exact 
                        path="/user" 
                        element={<Security_shield><Components.User /></Security_shield>} />

                        <Route 
                        exact 
                        path="/vendas" 
                        element={<Security_shield><Components.Vendas /></Security_shield>} />

                        <Route 
                        exact 
                        path="/relatorio" 
                        element={<Security_shield><Components.Relatorio /></Security_shield>} />


                        {/* <Route 
                        exact 
                        path="/dash" 
                        element={<Security_internal_shield><Components.Dash /></Security_internal_shield>} />  */}


                        <Route path="*" element={<Navigate to={'/'} />} />

                    </Routes>
                </BrowserRouter>
            </CompanyContext.Provider>
            </UserContext.Provider>
            </LoadingContext.Provider>
            </HandleScreensContext.Provider>
            </PreferencesContext.Provider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}

const Security_shield = props => {
    const [user, setUser] = useContext(UserContext)
    const [company, setCompany] = useContext(CompanyContext)

    useEffect(() => {
      if (!company.length) {
        axios
        .get(`${api}/api/manage/store`,{
          headers: {
            Authorization: user.token
          }
        })
        .then(response => setCompany(response.data))
        .catch(err => logout())
      }
  
      axios
      .get(`${api}/api/manage/check`,{
        headers: {
          Authorization: user.token
        }
      })
      .then(response => {})
      .catch(err => logout())
    },[])
    
    if (!localStorage.data || !user.token || !user.expiresIn) return logout()
    if (parseInt(user.expiresIn) <= Date.now()) return logout()

    return (
      <Menu 
      {...props}
      children={props.children}/>
    ) 
    
}