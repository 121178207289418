const float = (number,initial) => {
    number = number === '' ? 0 : number
    if (number===0) return number
    number = number.replace(',','.')
    number = Number.isNaN(parseFloat(number)) ? initial : (number[number.length - 1] === '.' ? number : parseFloat(number))
    return number
}

const integer = (number,initial) => {
    number = number === '' ? 0 : number
    number = Number.isNaN(parseInt(number)) ? initial : parseInt(number)
    return number
}

export {
    float,
    integer
}